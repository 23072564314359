<template>
  <div class="row wrapper">
    <div class="col-md-6">
      <div class="container">
        <div class="row">
          <a href="#" style="  text-decoration: none;">
            <logo/>
          </a>

          <div class="content col-xs-12" style="height: 71vh;">
            <about-us/>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <section v-if="showForm || number" class="round "></section>

      <form-request
          v-if="showForm && !number"
          @success="successSwicher"
          @close="closeForm"
      />
      <div v-if="!showForm && !number" class="formButton" @click="showForm=!showForm">
        <svg id="pulseSvg" style="position: absolute" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
          <circle cx="50" cy="50" r="48.5" fill="white" stroke="#4C72E7" stroke-width="3"/>
        </svg>
        <svg style="position: absolute; top: 20px; left: 17px;" xmlns="http://www.w3.org/2000/svg" width="59" height="59"
             viewBox="0 0 59 59" fill="none">
          <path
              d="M27.002 23.3035C27.002 21.8753 26.7207 20.4612 26.1741 19.1418C25.6276 17.8224 24.8266 16.6235 23.8167 15.6137C22.8069 14.6038 21.6081 13.8028 20.2886 13.2563C18.9692 12.7098 17.5551 12.4285 16.127 12.4285C14.6988 12.4285 13.2847 12.7098 11.9653 13.2563C10.6459 13.8028 9.447 14.6038 8.43717 15.6137C7.42733 16.6235 6.62628 17.8224 6.07976 19.1418C5.53324 20.4612 5.25195 21.8753 5.25195 23.3035M27.002 23.3035C27.002 26.1877 25.8562 28.9538 23.8167 30.9933C21.7773 33.0327 19.0112 34.1785 16.127 34.1785C13.2427 34.1785 10.4766 33.0327 8.43717 30.9933C6.39771 28.9538 5.25195 26.1877 5.25195 23.3035M27.002 23.3035H5.25195"
              stroke="#006FD5" stroke-width="3" stroke-linecap="round"/>
          <path opacity="0.5"
                d="M36.0813 17.5542C36.7499 18.2468 37.5498 18.7994 38.4342 19.1795C39.3187 19.5597 40.27 19.7599 41.2326 19.7685C42.1953 19.7771 43.15 19.5939 44.0411 19.2296C44.9322 18.8652 45.7418 18.3271 46.4226 17.6465C47.1035 16.966 47.642 16.1566 48.0068 15.2657C48.3715 14.3748 48.5552 13.4202 48.5471 12.4575C48.5389 11.4949 48.3392 10.5435 47.9594 9.65886C47.5796 8.77424 47.0275 7.97411 46.3352 7.30516C44.972 5.96365 43.1339 5.21515 41.2213 5.22271C39.3088 5.23027 37.4766 5.99328 36.1241 7.34553C34.7715 8.69778 34.0081 10.5297 34.0001 12.4423C33.9921 14.3549 34.7401 16.1931 36.0813 17.5567V17.5542Z"
                stroke="#006FD5" stroke-width="3" stroke-linecap="round"/>
          <path
              d="M50.9389 42.3493C52.6018 40.6489 53.5269 38.3613 53.5136 35.983C53.5003 33.6047 52.5497 31.3276 50.8679 29.6459C49.1862 27.9641 46.9091 27.0134 44.5308 27.0001C42.1525 26.9868 39.8649 27.912 38.1644 29.5748L29.6481 38.0912C27.9853 39.7916 27.0601 42.0792 27.0734 44.4575C27.0867 46.8358 28.0374 49.1129 29.7191 50.7947C31.4008 52.4764 33.6779 53.4271 36.0563 53.4404C38.4346 53.4537 40.7222 52.5285 42.4226 50.8657L50.9389 42.3493Z"
              stroke="#006FD5" stroke-width="3"/>
          <path opacity="0.5" d="M34.252 34.1785C34.252 34.1785 34.931 37.7068 38.8702 41.646C42.8094 45.5803 46.3353 46.2618 46.3353 46.2618"
                stroke="#006FD5" stroke-width="3"/>
        </svg>
      </div>
      <success-form
          v-if="number"
          :number="number"
      />
    </div>
  </div>
</template>
<script>
import AboutUs from "@/components/AboutUs";
import FormRequest from "@/components/FormRequest";
import Logo from "@/components/Logo";
import successForm from "@/components/SuccessForm";

export default {
  data() {
    return {
      number: null,
      showForm: false,
    };
  },
  components: {
    AboutUs,
    FormRequest,
    Logo,
    successForm
  },
  methods: {
    closeForm() {
      this.showForm = false;
    },
    successSwicher(value) {
      this.number = value;
    }
  },
  created() {
    setTimeout(() => {
      this.showForm = true;
    }, 2000);
  }
}
</script>
<style>
.formButton {
  cursor: pointer;
  position: absolute;
  bottom: 22vh;
  left: 70%;
  margin-bottom: 50px;

}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

#pulseSvg {
  animation: pulseAnimation 3s infinite;
  filter: url(#blurFilter);
}

@media (max-width: 768px) {
  .formButton {
    bottom: 38vh;
  }
}
</style>
