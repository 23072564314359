<template>
  <div class="admin-page">
    <h1 class="mb-0">Авторизация</h1>
    <small class="mb-5">Пожалуйста, авторизуйтесь</small>
    <form @submit.prevent="handleLogin" class="mt-3">
      <div class="form-group">
        <label for="username">Имя пользователя</label>
        <input
            id="username"
            v-model="username"
            type="text"
            class="form-control"
            placeholder="Введите имя пользователя"
            autocomplete="username"
            required
        />
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <input
            id="password"
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Введите пароль"
            autocomplete="current-password"
            required
        />
      </div>
      <button type="submit" class="btn btn-primary">Войти</button>
    </form>
    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    <p v-if="successMessage" class="text-success">{{ successMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      successMessage: '',
    };
  },
  methods: {
    async handleLogin() {
      try {
        let url = 'https://app.llolo.ru/api/v1/application/admin';
        // let url = 'http://10.1.197.75:443/api/v1/application/admin';
        const response = await axios.post(url, {
          username: this.username,
          password: this.password,
        });

        if (response.data.success) {
          this.successMessage = response.data.data.message;
          this.errorMessage = '';

          localStorage.setItem('token', response.data.data.token);

          this.$router.push('/alerts');
        } else {
          this.errorMessage = 'Ошибка авторизации';
          this.successMessage = '';
        }
      } catch (error) {
        this.errorMessage = 'Неправильные Имя пользователя или Пароль';
        this.successMessage = '';
        console.error('Ошибка авторизации:', error);
      }
    },
  },
};
</script>

<style scoped>
.admin-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
}

.btn {
  width: 100%;
}

.text-danger {
  color: red;
}

.text-success {
  color: green;
}
</style>
