<template>
  <div class="row wrapper">
    <div class="col-md-6">
      <div class="container">
        <div class="row">
          <a href="/" style="text-decoration: none; max-width: 0;">
            <logo/>
          </a>
          <div class="content col-xs-12" style="height: 71vh;">
            <alerts-toggle
                :massActive="mass_active"
                @updateMassActive="updateMassActive"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 align-content-end alerts-wrapper">
      <alerts-list
          :massActive="mass_active"
          @updateMassActive="updateMassActive"
      />
    </div>
  </div>
</template>

<script>
import AlertsToggle from "@/components/AlertsToggle";
import Logo from "@/components/Logo";
import AlertsList from "@/components/AlertsList";

export default {
  components: {
    AlertsToggle,
    Logo,
    AlertsList,
  },
  data() {
    return {
      mass_active: true,
    };
  },
  methods: {
    async updateMassActive(newMassActive) {
      this.mass_active = newMassActive;
    },
  },
  created() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push('/admin');
    }
  }
};
</script>
