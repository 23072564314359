import axios from 'axios';
import router from '@/router';

const API_BASE_URL = 'https://app.llolo.ru/api/v1/application/alerts';
// const API_BASE_URL = 'http://10.1.197.75:443/api/v1/application/alerts' || process.env.BASE_URL;

async function sendRequest(data = {}, method = 'GET') {
    try {
        const token = localStorage.getItem('token');
        if (router.currentRoute.value.path !== '/admin' && !token) {
            await router.push('/admin');
            return null;
        }
        const config = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            method,
            params: method === 'GET' ? data : undefined,
            data: method !== 'GET' ? data : undefined,
        };

        const response = await axios(API_BASE_URL, config);

        if (response.data.success) {
            return response.data;
        } else {
            console.error('Не удалось обработать запрос:', response.data);
            return null;
        }
    } catch (error) {
        console.error('Ошибка во время запроса:', error);
        if (router.currentRoute.value.path !== '/admin') {
            await router.push('/admin');
        }
        return null;
    }
}

export default sendRequest;
