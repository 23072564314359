<template>
  <div class="alert-wrap">
    <div v-if="loading" class="alert-loading">Загрузка...</div>
    <div v-else>
      <div class="alert-content">
        <ul class="alert-list">
          <li v-for="alert in alerts" :key="alert.id">
            <div class="alert-item row ml-0 mr-0 align-content-center flex-nowrap">
              <div class="checkbox-container col-1">
                <input
                    type="checkbox"
                    class="checkbox-input"
                    :disabled="hasActiveCheckboxesOnPage && !alert.active"
                    :checked="alert.active"
                    @change="toggleActivate(alert.id)"
                />
              </div>
              <textarea
                  class="alert-item-text col-9"
                  v-model="alert.message"
                  maxlength="500"
                  rows="4"
                  @input="truncateMessage(alert, 'message')"
                  @blur="confirmUpdateAlert(alert.id)"
              ></textarea>
              <button class="alert-item-del col-1" @click="confirmDeleteAlert(alert.id)"
                      aria-label="Удалить"></button>
            </div>
          </li>
        </ul>
      </div>
      <div class="row offset-1 alert-item mr-0 align-content-center flex-nowrap" style="overflow-y: scroll;">
        <textarea
            class="alert-item-text col-9"
            v-model="newAlertMessage"
            maxlength="500"
            rows="4"
            @input="truncateMessage($data, 'newAlertMessage')"
            placeholder="Новый пост..."
        ></textarea>
        <button class="alert-item-add col-1" @click="addAlert" aria-label="Добавить пост"></button>
      </div>
    </div>
    <PaginationControls
        v-if="totalPages > 1"
        :current-page="currentPage"
        :total-pages="totalPages"
        @change-page="handlePageChange"
    />
    <AlertModal
        v-if="showModal"
        :message="modalMessage"
        :originalMessage="editingMessage"
        @confirm="handleModalConfirm"
        @close="handleModalClose"
    />
  </div>
</template>

<script>
import PaginationControls from './PaginationControls.vue';
import AlertModal from "@/components/AlertModal.vue";
import sendRequest from '@/utils/api';

export default {
  name: 'AlertsList',
  components: {
    AlertModal,
    PaginationControls
  },
  props: {
    massActive: Boolean,
  },
  data() {
    return {
      alerts: [],
      originalAlerts: [],
      newAlertMessage: '',
      editingAlert: null,
      editingMessage: '',
      loading: true,
      selectedAlert: null,
      currentPage: 1,
      totalPages: 1,
      totalCount: 0,
      showModal: false,
      modalMessage: '',
    };
  },
  computed: {
    hasActiveCheckboxesOnPage() {
      return this.alerts.some((alert) => alert.active);
    }
  },
  methods: {
    async handlePageChange(newPage) {
      this.currentPage = newPage;
      await this.fetchAlerts(newPage);
    },
    async fetchAlerts(page = 1, perPage = 10) {
      try {
        this.loading = true;
        const getAlerts = {
          page: page,
          per_page: perPage,
        };
        const response = await sendRequest(getAlerts, 'GET');
        if (response && response.success) {
          this.alerts = response.data.map(alert => ({
            id: alert.id,
            message: alert.message,
            active: alert.active
          }));
          this.originalAlerts = JSON.parse(JSON.stringify(this.alerts));
          this.currentPage = response.current_page;
          this.totalPages = response.total_pages;
          this.totalCount = response.total_count;
          this.$emit('updateMassActive', response.mass_active);
        } else {
          console.warn('Ошибка: данные не были загружены успешно.');
        }
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      } finally {
        this.loading = false;
      }
    },
    async addAlert() {
      try {
        if (!this.newAlertMessage.trim()) return;
        const newAlert = {
          message: this.newAlertMessage
        };
        const result = await sendRequest(newAlert, 'POST');
        if (result && result.success) {
          this.alerts.push(result.data);
          this.originalAlerts.push(JSON.parse(JSON.stringify(result.data)));
          this.newAlertMessage = '';
          await this.fetchAlerts();
        } else {
          console.warn('Ошибка добавления нового поста.');
        }
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      }
    },
    async updateAlert(alertId) {
      try {
        const updatedAlert = this.checkMessageWasChanged(alertId);
        if (!updatedAlert) {
          return null;
        }
        const result = await sendRequest(updatedAlert, 'PUT');
        if (!result) {
          return null;
        }
        const alertIndex = this.alerts.findIndex(p => p.id === alertId);
        if (alertIndex === -1) {
          return null;
        }
        this.alerts[alertIndex] = {
          ...this.alerts[alertIndex],
          ...updatedAlert,
        };
        this.originalAlerts[alertIndex] = JSON.parse(JSON.stringify(this.alerts[alertIndex]));
        this.editingAlert = null;
        this.editingMessage = "";
        return result;
      } catch (error) {
        console.error('Ошибка обновления записи', error);
      }
      return null;
    },
    confirmUpdateAlert(alertId) {
      setTimeout(() => {
        const activeElement = document.activeElement;
        if (activeElement && activeElement.classList.contains('alert-item-del')) {
          return;
        }
        const currentAlert = this.checkMessageWasChanged(alertId);
        if (!currentAlert) {
          return;
        }
        this.modalMessage = 'Обновить изменяемый пост?';
        this.editingAlert = currentAlert;
        this.editingMessage = currentAlert.message;
        this.showModal = true;
      }, 0);
    },
    async deleteAlert(alertId) {
      try {
        const deletedAlert = {
          id: alertId,
          message: this.editingAlert.message,
          active: this.editingAlert.active
        };

        const result = await sendRequest(deletedAlert, 'DELETE');
        if (result && result.success) {
          this.newAlertMessage = '';
          let alertsLength = (this.hasActiveCheckboxesOnPage) ? this.alerts.length < 3 : this.alerts.length < 2;
          if (alertsLength && this.currentPage > 1) {
            this.currentPage -= 1;
          }

          await this.fetchAlerts(this.currentPage);
        } else {
          console.warn('Ошибка при удалении поста.');
        }
      } catch (error) {
        console.error('Ошибка при отправке данных:', error);
      }
    },
    confirmDeleteAlert(alertId) {
      const alertIndex = this.alerts.findIndex(alert => alert.id === alertId);
      if (alertIndex === -1) {
        return;
      }
      this.modalMessage = 'Вы уверены, что хотите удалить этот пост?';
      this.editingAlert = this.originalAlerts[alertIndex];
      this.editingMessage = this.originalAlerts[alertIndex].message;
      this.showModal = true;
    },
    handleModalConfirm() {
      if (this.showModal && this.modalMessage === 'Вы уверены, что хотите удалить этот пост?') {
        this.deleteAlert(this.editingAlert.id);
      } else {
        this.updateAlert(this.editingAlert.id);
      }
      this.closeModal();
    },
    async toggleActivate(alertId) {
      const alert = this.alerts.find((a) => a.id === alertId);
      if (!alert) {
        return;
      }
      alert.active = !alert.active;
      try {
        const result = await this.updateAlert(alertId);
        if (result && result.success) {
          alert.active = result.data.active;
          await this.fetchAlerts();
        } else {
          console.error("Ошибка изменения состояния чекбокса");
          alert.active = !alert.active;
        }
      } catch (error) {
        console.error("Ошибка переключения чекбокса:", error);
        alert.active = !alert.active;
      } finally {
        this.updateActivateStates();
      }
    },
    updateActivateStates() {
      if (!this.hasActiveCheckboxesOnPage) {
        this.alerts.forEach(alert => {
          alert.disabled = false;
        });
      } else {
        this.alerts.forEach(alert => {
          alert.disabled = !this.massActive && (this.selectedAlert && this.selectedAlert !== alert.id);
        });
      }
    },
    truncateMessage(target, key) {
      if (target[key].length > 500) {
        target[key] = target[key].slice(0, 500);
        this.alerts = [...this.alerts];
      }
    },
    checkMessageWasChanged(alertId) {
      const alertIndex = this.alerts.findIndex(alert => alert.id === alertId);
      const originalAlert = this.originalAlerts[alertIndex];
      const currentAlert = this.alerts[alertIndex];

      if (alertIndex !== -1) {
        if (
            (originalAlert.message === currentAlert.message)
            && (originalAlert.active === currentAlert.active)
        ) {
          return null;
        }
      }
      return currentAlert;
    },
    handleModalClose() {
      if (this.editingAlert) {
        const alertIndex = this.alerts.findIndex(alert => alert.id === this.editingAlert.id);
        if (alertIndex !== -1) {
          this.alerts[alertIndex].message = this.originalAlerts[alertIndex].message;
        }
      }
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
      this.modalMessage = '';
    },
  },
  created() {
    this.fetchAlerts();
  }
};
</script>

<style scoped>
.alert-list {
  list-style: none;
  padding: 0;
}

.alert-item {
  margin-bottom: 15px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
}

.checkbox-input {
  appearance: none;
  width: 32px;
  height: 32px;
  border: 1px solid #86b7fe;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
}

.checkbox-input:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.checkbox-input:checked::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 8px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.checkbox-input:focus {
  outline: none;
  box-shadow: 0 0 4px #86b7fe;
}

.checkbox-input:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.alert-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  height: 100%;
}

.alert-content {
  height: 61vh;
  border-radius: 40px;
  border: 1px solid #86B7FE;
  margin-bottom: 4vh;
  padding-top: 15px;
  margin-right: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}

.alert-item {
  padding: 2px;
}

.alert-item-text {
  flex-grow: 2;
  resize: none;
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #86b7fe;
  background: #fff;
  color: #333;
  padding: 16px 32px;
  border-radius: 40px;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.alert-item-text:focus,
.alert-item-text:target,
.alert-item-text:active,
.alert-item-text:focus-visible {
  outline: 1px solid #86b7fe;
}

.alert-item-del {
  min-width: 78px;
  height: 20px;
  align-self: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.alert-item-del::before,
.alert-item-del::after {
  content: '';
  position: absolute;
  width: 17px;
  height: 2px;
  background-color: #818A99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.alert-item-del::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.alert-item-del:hover::before,
.alert-item-del:hover::after {
  background-color: #084298;
}

.alert-item-del:focus {
  outline: none;
}

.alert-item-add {
  min-width: 78px;
  height: 20px;
  align-self: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.alert-item-add::before,
.alert-item-add::after {
  content: '';
  position: absolute;
  width: 17px;
  height: 2px;
  background-color: #86b7fe;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert-item-add::after {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.alert-item-add:hover::before,
.alert-item-add:hover::after {
  background-color: #084298;
}

.alert-item-add:focus {
  outline: none;
}

.alert-loading {
  text-align: center;
}
</style>
