<template>
  <div class="modal-overlay">
    <div class="modal">
      <h5 class="contentRow">{{ message }}</h5>
      <div class="modal-actions">
        <button @click="onConfirm" class="btn-confirm">Да</button>
        <button @click="onClose" class="btn-cancel">Нет</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertModal',
  props: {
    message: {
      type: String,
      required: true,
    },
    originalMessage: {
      type: String,
      required: true,
    }
  },
  emits: ['confirm', 'close'],
  data() {
    return {
      previousMessage: this.originalMessage,
    };
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', this.previousMessage);
      },
    onClose() {
      this.$emit('close', this.previousMessage);
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  height: fit-content;
}

.modal-actions {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.btn-confirm,
.btn-cancel {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.btn-confirm {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-confirm:hover {
  background-color: #0056b3;
}

.btn-cancel {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-cancel:hover {
  background-color: #999;
}
</style>
