<template>
  <section id="form" class="form">
    <header class="row text-right ">
      <svg class=" ml-auto mr-12 closeButton" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"
           stroke-width="1.5" stroke-linecap="round"
           stroke-linejoin="round" stroke="currentColor"
           @click="closeChat"
      >
        <path d="M18 6 6 18M6 6l12 12"/>
      </svg>
    </header>
    <div class="chat  justify-content-end" id="chat">
      <div>
        <div class="offset-1 col-xs-10 form-title" style="max-width: 90% !important;">
          <span class="formTitle">ЛЛОЛО Помощник</span>
        </div>
        <div class="messagesItem  mr-0 bottom-row row" id="welcomeMessage" style="opacity: 1" v-if="welcomeMessage">
          <div class="col-xl-1 avaBlock">
            <div>
              <div class="ava">
                <img :id="`messageTwo_${index}`" style=" position: absolute;bottom: 0; left: 31px;" :src="getLogo" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-10 col-sm-12" style="max-width: 90% !important;">
            <div class="welcomeMessage">
              <div class="message">
                Ваш личный ассистент ЛЛОЛО приветствует Вас!
                <div class="currentTime text-left"><span>{{ currentTime }}</span></div>
              </div>
            </div>
            <div v-if="alertMessage" class="welcomeMessage alerts-message">
              <div class="message">
                {{ alertMessage }}
                <div class="currentTime text-left"><span>{{ currentTime }}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(message, index) in this.chatData.message" :key="index" :scroll="scrollTo">
          <div v-if="message.user" class="requestForm message" :id="`requestForm_${index}`" style="opacity: 1; background:#FFF !important;">
            <div class="formInputs">
              <span>{{ message.user }}</span>
              <div class="currentTime text-left"><span style="position: relative; right: 12px;">{{ currentTime }}</span></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path d="M0 0H8V4.68629C8 6.80802 8.84285 8.84285 10.3431 10.3431L14.2929 14.2929C14.9229 14.9229 14.4767 16 13.5858 16H0V0Z"
                      fill="#E7F1FF"/>
              </svg>
            </div>
          </div>
          <div class="row" :id="`messageTwo_${index}`" style="opacity: 1;  margin-right: -47px;">
            <div class="col-xl-1 d-none d-xl-block avaBlock">
              <div class="">
                <div class="ava">
                  <img :id="`movingPicture_${index}`" style=" position: absolute;bottom: 0px;left: 31px;" :src="getLogo" alt="">
                </div>
              </div>
            </div>
            <div class="col-lg-10 col-sm-12">
              <div class="fillForm welcomeMessage" id="fillForm">
                <div class="message">
                  {{ message.bot }}
                  <div class="currentTime text-left"><span>{{ currentTime }}</span></div>
                </div>
                <svg style="position: absolute; bottom: 4px; left: 35px;" class="tail" xmlns="http://www.w3.org/2000/svg" width="15" height="16"
                     viewBox="0 0 15 16" fill="none">
                  <path d="M15 0H7V4.68629C7 6.80802 6.15715 8.84285 4.65686 10.3431L0.707107 14.2929C0.0771421 14.9229 0.523308 16 1.41421 16H15V0Z"
                        fill="#F0F4FA"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showForm">
          <div v-if="requestForm" class="requestForm" id="requestForm" style="opacity: 1">
            <div class="formInputs">
              <div>
                <div class="placeholders">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="organization" class="card-title">Название организации</label></div>
                    <div class="col-md-12">
                      <input class="form-control"
                             v-model.trim="chatData['organization']"
                             id="organization"
                             name="organization"
                             type="text"
                             placeholder="ГБУЗ/ООО"
                      >
                      <div v-if="error.organization">
                        <img class="danger-icon" :src="getIcon"/>
                        <span class="error-message">{{ error.organization }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="placeholders">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="position" class="card-title">Должность</label></div>
                    <div class="col-md-12">
                      <input class="form-control"
                             v-model.trim="chatData['position']"
                             id="position"
                             name="position"
                             placeholder="Директор"
                             type="text"
                      >
                      <div v-if="error.position">
                        <img class="danger-icon" :src="getIcon"/>
                        <span class="error-message">{{ error.position }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="placeholders">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="full_name" class="card-title">Фамилия Имя Отчество</label>
                    </div>
                    <div class="col-md-12">
                      <input class="form-control"
                             v-model.trim="chatData['full_name']"
                             id="full_name"
                             name="full_name"
                             placeholder="Иванов Иван"
                             type="text"
                      >
                      <div v-if="error.full_name">
                        <img class="danger-icon" :src="getIcon"/>
                        <span class="error-message">{{ error.full_name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="placeholders">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="email" class="card-title">Email</label>
                    </div>
                    <div class="col-md-12">
                      <input placeholder="example@mail.com"
                             id="email"
                             name="email"
                             class="form-control"
                             v-model.trim="chatData['email']"
                      />
                      <div v-if="error.email">
                        <img class="danger-icon" :src="getIcon"/>
                        <span class="error-message">{{ error.email }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="placeholders">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="phone" class="card-title">Телефон</label>
                    </div>
                    <div class="col-md-12">
                      <input class="form-control"
                             id="phone"
                             name="phone"
                             placeholder="+7("
                             v-mask="'+7(###)###-##-##'"
                             v-model.trim="chatData['phone']"
                      >
                      <div v-if="error.phone">
                        <img class="danger-icon" :src="getIcon"/>
                        <span class="error-message">{{ error.phone }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="placeholders" style="margin-bottom: 0 !important;">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="problem" class="card-title">Комментарий</label></div>
                    <div class="col-md-12 problem">
                                    <textarea class="form-control"
                                              id="problem"
                                              name="problem"
                                              placeholder="Опишите Вашу проблему"
                                              v-model.trim="chatData['problem']"
                                              maxlength="1000"
                                              minlength="5"
                                    />
                      <p style="color: #6C757D; margin-top: 4px;">Не более {{ problemCount() }} символов</p>
                      <div v-if="error.problem" style="position: relative; bottom: 24px;">
                        <img class="danger-icon" style="position: absolute; right: 11px; top: -64px;" :src="getIcon"/>
                      </div>
                      <span v-if="error.problem" class="error-message">{{ error.problem }}</span>

                      <div class="send-error" v-if="error.sendError">
                        <span>Ошибка отправки сообщения, повторите попытку <img :src="$route.path + 'arrow-wrapper.svg'"/></span>
                        <img class="" :src="getIconError"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                <path d="M0 0H8V4.68629C8 6.80802 8.84285 8.84285 10.3431 10.3431L14.2929 14.2929C14.9229 14.9229 14.4767 16 13.5858 16H0V0Z"
                      fill="#E7F1FF"/>
              </svg>
            </div>
          </div>
        </div>
      </div>

    </div>
    <footer class="container">
      <div class="placeholders">
        <div class="col-md-12">
          <input class="form-control"
                 v-model="message"
                 id="writeMessage"
                 name="writeMessage"
                 placeholder="Написать сообщение..."
                 type="text"
                 :disabled="disable"
                 @keydown.enter="sendForm"
          >
        </div>
      </div>
      <div class="sendFile">
        <div class="fileInputs container">
          <div class="" style="display: flex">
            <div class="col-md-5 fileInput">
<!--todo              aib515211-->
<!--              <span class="fileInputItem">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">-->
<!--                  <path-->
<!--                      d="M11.7109 22C10.1776 22 8.8776 21.4667 7.81094 20.4C6.74427 19.3333 6.21094 18.0333 6.21094 16.5V6C6.21094 4.9 6.6026 3.95833 7.38594 3.175C8.16927 2.39167 9.11094 2 10.2109 2C11.3109 2 12.2526 2.39167 13.0359 3.175C13.8193 3.95833 14.2109 4.9 14.2109 6V15.5C14.2109 16.2 13.9693 16.7917 13.4859 17.275C13.0026 17.7583 12.4109 18 11.7109 18C11.0109 18 10.4193 17.7583 9.93594 17.275C9.4526 16.7917 9.21094 16.2 9.21094 15.5V6H10.7109V15.5C10.7109 15.7833 10.8069 16.0207 10.9989 16.212C11.1903 16.404 11.4276 16.5 11.7109 16.5C11.9943 16.5 12.2319 16.404 12.4239 16.212C12.6153 16.0207 12.7109 15.7833 12.7109 15.5V6C12.7109 5.3 12.4693 4.70833 11.9859 4.225C11.5026 3.74167 10.9109 3.5 10.2109 3.5C9.51094 3.5 8.91927 3.74167 8.43594 4.225C7.9526 4.70833 7.71094 5.3 7.71094 6V16.5C7.71094 17.6 8.1026 18.5417 8.88594 19.325C9.66927 20.1083 10.6109 20.5 11.7109 20.5C12.8109 20.5 13.7526 20.1083 14.5359 19.325C15.3193 18.5417 15.7109 17.6 15.7109 16.5V6H17.2109V16.5C17.2109 18.0333 16.6776 19.3333 15.6109 20.4C14.5443 21.4667 13.2443 22 11.7109 22Z"-->
<!--                      fill="black" fill-opacity="0.4"/>-->
<!--                </svg>-->
<!--                Вложить файлы-->
<!--              </span>-->
            </div>
            <div class="col-md-1 offset-md-4">
              <button  :disabled="disable" @click="sendForm"  @keydown.enter="sendForm" class="sendButton">
                Отправить
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="ikons/send-fill" clip-path="url(#clip0_2457_4124)">
                    <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.80867 12.753L6.17966 9.49604C4.47459 6.08698 3.62151 4.38191 4.40504 3.59838C5.18748 2.81594 6.89364 3.66793 10.3027 5.373L22.6381 11.5402C25.0376 12.7399 26.2374 13.3398 26.2374 14.2896C26.2374 15.2394 25.0376 15.8393 22.6403 17.039L10.3027 23.2062C6.89364 24.9113 5.18857 25.7643 4.40504 24.9808C3.6226 24.1984 4.47459 22.4922 6.17966 19.0831L7.80649 15.8262L17.2697 15.8262C17.6726 15.8192 18.0567 15.6543 18.3391 15.3668C18.6216 15.0794 18.7799 14.6926 18.7799 14.2896C18.7799 13.8866 18.6216 13.4998 18.3391 13.2124C18.0567 12.9249 17.6726 12.76 17.2697 12.753L7.80867 12.753Z"
                          fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2457_4124">
                      <rect width="28" height="28" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </button>
<!--              <button class="loader" style="background: #2647AD;" v-else>-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">-->
<!--                  <path d="M14.2109 3.5V7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M21.6374 6.57349L19.1655 9.04536" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M24.7109 14H21.2109" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M21.6374 21.4266L19.1655 18.9547" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M14.2109 24.5V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M6.78418 21.4266L9.25605 18.9547" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M3.71094 14H7.21094" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M6.78418 6.57349L9.25605 9.04536" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
<!--              </button>-->
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>
<script>
import axios from 'axios';
import { mask } from 'vue-the-mask';

export default {
  name: "formRequest",
  directives: {
    mask,
  },
  components: {},
  props: ['success'],

  data() {
    return {
      welcomeMessage: false,
      requestForm: false,
      messageTwo: false,
      phone: null,
      chatData: {},
      loading: false,
      error: [],
      disable: false,
      message: '',
      threadId: 'None',
      waiting: false,
      showForm: false,
      alertMessage: '',
      index: 0,
    };
  },
  computed: {
    closeChat() {
      return this.$emit('close', true);
    },
    currentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    getLogo() {
      return this.$route.path + 'fillFormAva.png';
    },
    getIcon() {
      return this.$route.path + 'icon-wrapper.svg';
    },
    getIconError() {
      return this.$route.path + 'Avatar.svg';
    },
  },
  methods: {
    problemCount() {
      if (!this.chatData['problem']) {
        this.chatData['problem'] = '';
      }

      let counter = 1000 - this.chatData['problem'].length;
      if (counter < 0) {
        this.error['problem'] = 'Поле не может содержать более 1000 символов';
        document.getElementById("problem").classList.add("border-danger");
        counter = 0;
      }

      return counter;
    },
    lengthCheck(string, value) {
      return string.length > value
    },
    checkForNumbers(value) {
      return /^\d{1,}$/.exec(value);
    },
    checkFields() {
      let text = 'Пожалуйста заполните это поле';
      let chatData = this.chatData;
      this.error = [];
      if (chatData.organization) {
        if (this.lengthCheck(chatData.organization.length, 100)) {
          this.error['organization'] = 'Поле не может содержать более 100 символов';
          document.getElementById("organization").classList.add("border-danger");
          this.disable = false;
          return true;
        }
        if (this.checkForNumbers(chatData.organization)) {
          this.error['organization'] = 'Поле не может состоять только из цифр';
          document.getElementById("organization").classList.add("border-danger");
          this.disable = false;
          return true;
        }

        document.getElementById("organization").classList.remove("border-danger");
      } else {
        this.error['organization'] = text;
        document.getElementById("organization").classList.add("border-danger");
        this.disable = false;
        return true;
      }
      if (chatData.position) {
        if (this.lengthCheck(chatData.position.length, 70)) {
          this.error['position'] = 'Поле не может содержать более 70 символов';
          document.getElementById("position").classList.add("border-danger");
          this.disable = false;
          return true;
        }
        if (this.checkForNumbers(chatData.position)) {
          this.error['position'] = 'Поле не может состоять только из цифр';
          document.getElementById("position").classList.add("border-danger");
          this.disable = false;
          return true;
        }

        document.getElementById("position").classList.remove("border-danger");
      } else {
        this.error['position'] = text;
        document.getElementById("position").classList.add("border-danger");
        this.disable = false;
        return true;
      }
      if (chatData.full_name) {
        let str = chatData.full_name;
        let words = str.split(" ");

        if (words.length < 2) {
          this.error['full_name'] = 'Введите корректные ФИО';
          document.getElementById("full_name").classList.add("border-danger");
          this.disable = false;
          return true;
        }
        if (!(/^[A-Za-zА-яа-я\s-]+$/.exec(chatData.full_name))) {
          this.error['full_name'] = 'Поле не может содержать цифры';
          document.getElementById("full_name").classList.add("border-danger");
          this.disable = false;
          return true;
        }
        if (this.lengthCheck(chatData.position.length, 100)) {
          this.error['full_name'] = 'Поле не может содержать более 100 символов';
          document.getElementById("full_name").classList.add("border-danger");
          this.disable = false;
          return true;
        }
        if (chatData.full_name.length < 5) {
          this.error['full_name'] = 'Поле не может содержать менее 5 символов';
          document.getElementById("full_name").classList.add("border-danger");
          this.disable = false;
          return true;
        }

        document.getElementById("full_name").classList.remove("border-danger");
      } else {
        this.error['full_name'] = text;
        document.getElementById("full_name").classList.add("border-danger");
        this.disable = false;
        return true;
      }
      if (chatData.email) {
        let email = chatData.email;
        let regex = /\S+@\S+\.\S{2,3}/;
        if (!regex.exec(email)) {
          this.error['email'] = "Email введен в неправильном формате.";
          document.getElementById("email").classList.add("border-danger");
          this.disable = false;
          return true;
        }

        document.getElementById("email").classList.remove("border-danger");
      } else {
        this.error['email'] = text;
        document.getElementById("email").classList.add("border-danger");
        this.disable = false;
        return true;
      }
      if (chatData.phone) {
        if (chatData.phone.length < 16) {
          document.getElementById("phone").classList.add("border-danger");
          this.error['phone'] = 'Введите полный номер телефона';
          this.disable = false;
          return true;
        }
        document.getElementById("phone").classList.remove("border-danger");
      } else {
        this.error['phone'] = text;
        document.getElementById("phone").classList.add("border-danger");
        this.disable = false;
        return true;
      }
      if (chatData.problem) {
        if (this.lengthCheck(chatData.problem.length, 1000)) {
          this.error['problem'] = 'Поле не может содержать более 1000 символов';
          document.getElementById("problem").classList.add("border-danger");
          this.disable = false;
          return true;
        }
        if (chatData.problem.length < 5) {
          this.error['problem'] = 'Поле не может содержать  менее 5 символов';
          document.getElementById("problem").classList.add("border-danger");
          this.disable = false;
          return true;
        }
        document.getElementById("problem").classList.remove("border-danger");
      } else {
        this.error['problem'] = text;
        document.getElementById("problem").classList.add("border-danger");
        this.disable = false;
        return true;
      }

      return false;
    },
    async sendForm() {
      this.scrollTo();
      if ((!this.message || this.message.length === 0) && !this.showForm) {
        this.disable = false;
        this.loading = false;
        return false
      }

      if (this.showForm === true && this.checkFields()) {
        this.disable = false;
        this.loading = false;
        return false;
      }

      this.disable = true;
      this.disable = true;
      this.loading = true;
      this.waiting = true;
      let formRequest = false;

      if (!this.chatData['message']) {
        this.chatData['message'] = [];
      }
      this.chatData['message'].push({'user': this.message});
      let url = 'https://app.llolo.ru/api/v1/application/chat'
      // let url = 'http://10.1.197.75:443/api/v1/application/chat'
      let data = {'thread_id': this.threadId, 'message': this.message};
      this.message = null;
      if (this.threadId && this.showForm) {
        data = this.chatData;
        url = 'https://app.llolo.ru/api/v1/application'
        // url = 'http://10.1.197.75:443/api/v1/application'
        formRequest = true;
      }

      let countData = this.chatData['message'].length - 1;
      if (!this.showForm) {
        this.chatData['message'][countData]['bot'] = 'Печатает...';
      }
      await axios.post(url, data, {
            headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json',
            }
          },
      ).then(response => {
        this.showForm = response.data.response_body.form === 'true' ? true : false;
        this.threadId = response.data.response_body.thread_id;
        if (formRequest) {
         return  this.$emit('success', response.data.response_body.id);
        } else {
          this.chatData['message'][countData]['bot'] = response.data.response_body.message;
        }
        this.loading = false;
        this.disable = false;
        this.waiting = false;
        if (!this.showForm) {
          this.scrollTo()
        }
      })
          .catch(error => {
            this.error.sendError = true;
            this.loading = false;
            if (error.response && error.response.status && Number(error.response.status) === 400) {
              // window.location.href = '/400';
              return false;
            }
            if (error.response && error.response.status && Number(error.response.status) === 404) {
              window.location.href = '/404';
              return false;
            } else {
              window.location.href = '/504';
              return false;
            }
          });
    },
    async fetchAlertMessage() {
      try {
        const url = 'https://app.llolo.ru/api/v1/application'
        // const url = 'http://10.1.197.75:443/api/v1/application'
        const response = await axios.get(url);
        const data = response.data;

        if (data.data.mass_active && data.success) {
          this.alertMessage = data.data.message;
        } else {
          this.alertMessage = "";
        }
      } catch (error) {
        console.error("Ошибка при получении сообщения:", error);
        this.alertMessage = "";
      }
    },
    scrollTo() {
      setTimeout(() => {
        let chat = document.getElementById("chat");
        chat.scrollTop = chat?.scrollHeight;
      }, 100);
    }
  },
  created() {
    this.welcomeMessage = true;
    this.fetchAlertMessage();
    setTimeout(() => {
      this.requestForm = true;
    }, 5600);
  }
}
</script>
