<template>
  <div class="container">
    <div class="row ">
      <div class=" col-md-12 contentRow">
      </div>
    </div>
    <div class="row ">
      <div class=" col-md-12 contentRow">
        <h5>Для уведомления о массовом сбое <br>выберите шаблон или создайте <br> новый</h5>
      </div>
    </div>
    <div class="row ">
      <div class=" col-md-12 contentRow">
        <p class="description">
        </p>
      </div>
    </div>
    <div class="row " style="width: 509px;">
      <div class=" col-md-12 contentRow needHelp" style="width: 509px;">
        <div>
          <div
              @click="toggleSwitch"
              :class="{
        'np-outer-switch': true,
        'np-outer-switch-on': isEnabled,
        'np-outer-switch-off': !isEnabled,
      }"
          >
            <div
                :class="{
          'np-inner-switch': true,
          'np-inner-switch-left': !isEnabled,
          'np-inner-switch-right': isEnabled,
        }"
            ></div>
          </div>
        </div>
      </div>
    </div>
<!--    todo если понадобится функционал логирования МС-->
<!--    <div class="row" style="margin-top: 19vh;">-->
<!--      <div class="col-md-12 contacts  mb-0" style="width: 100%">-->
<!--        <p>-->
<!--          <a href="#" @click.prevent="toggleLoggingMode">{{-->
<!--              isLoggingEnabled ? 'Выключить лог MC' : 'Включить лог MC'-->
<!--            }}</a>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import sendRequest from '@/utils/api';

export default {
  name: 'alertToggle',
  props: {
    massActive: Boolean,
    sendRequest: Function
  },
  data() {
    return {
      isEnabled: this.massActive,
      isLoggingEnabled: false
    };
  },
  watch: {
    massActive(newValue) {
      this.isEnabled = newValue;
    },
  },
  methods: {
    async toggleSwitch() {
      this.isEnabled = !this.isEnabled;
      this.$emit('updateMassActive', this.isEnabled);

      const result = await sendRequest({mass_active: this.isEnabled}, 'PUT');

      if (result && result.success) {
        this.isEnabled = result.data.mass_active;
        this.$emit('updateMassActive', this.isEnabled);
      } else {
        console.error('Ошибка изменения переключателя массового сбоя');
        this.isEnabled = !this.isEnabled;
      }
    }
    //todo если понадобится функционал логирования МС
    // async toggleLoggingMode() {
    //   this.isLoggingEnabled = !this.isLoggingEnabled;
    //   const result = await this.sendRequest({logging: this.isLoggingEnabled}, 'PUT');
    //   if (!result) {
    //     this.isLoggingEnabled = !this.isLoggingEnabled;
    //   }
    // },
    // async fetchInitialStates() {
    //   try {
    //     const response = await axios.get();
    //     if (response.data.status === 'ok') {
    //       this.isEnabled = response.data.switch;
    //       this.isLoggingEnabled = response.data.logging;
    //       this.mass_active = response.data.mass_active;
    //     } else {
    //       console.error('Failed to fetch initial states:', response.data);
    //     }
    //   } catch (error) {
    //     console.error('Error fetching initial states:', error);
    //   }
    // }
  }
  // async mounted() {
  //   await this.fetchInitialStates();
  // }
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #007bff;
}

h3 {
  text-align: left;
}

.np-outer-switch {
  width: 70px;
  height: 34px;
  border-radius: 30px;
  background: rgb(212, 212, 212);
  cursor: pointer;
  position: relative;
}

.np-outer-switch-off {
  background: rgb(212, 212, 212);
  transition: background 0.4s;
}

.np-outer-switch-on {
  background: rgb(0, 123, 255);
  transition: background 0.4s;
}

.np-inner-switch {
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.np-inner-switch-left {
  top: 2px;
  left: 2px;
  transition: left 0.4s;
}

.np-inner-switch-right {
  top: 2px;
  left: 38px;
  transition: left 0.4s;
}
</style>
