import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminView from '../views/AdminView.vue'
import AlertView from '../views/AlertView.vue'
import ErrorPage from "@/components/ErrorPage";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminView
    },
    {
        path: '/alerts',
        name: 'alerts',
        component: AlertView
    },
    {
        path: '/404',
        component: ErrorPage,
        meta: {
            title: '404',
            message: 'К сожалению такой страницы не существует :('
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: ErrorPage,
        meta: {
            title: '404',
            message: 'К сожалению такой страницы не существует :('
        }
    },
    {
        path: '/400',
        // name: 'errorPage',
        component: ErrorPage,
        meta: {
            title: '400',
            message: 'Ошибка создания заявки'
        }
    },
    {
        path: '/504',
        // name: 'errorPage',
        component: ErrorPage,
        meta: {
            title: '504',
            message: 'Превышен лимит ожидания'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
