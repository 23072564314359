<template>
  <div class="pagination-controls row w-100 justify-content-center">
    <button
        :disabled="currentPage === 1"
        @click="$emit('change-page', currentPage - 1)"
        class="btn-arrow btn-back"
        aria-label="Предыдущая страница"
    >
      &lt;
    </button>
    <span class="pagination-label">
      Страница {{ currentPage }} из {{ totalPages }}
    </span>
    <button
        :disabled="currentPage === totalPages"
        @click="$emit('change-page', currentPage + 1)"
        class="btn-arrow btn-forward"
        aria-label="Следующая страница"
    >
      &gt;
    </button>
  </div>
</template>

<script>
export default {
  name: "PaginationControls",
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.pagination-controls {
  margin-top: 20px;
}

.btn-arrow {
  background-color: transparent;
  border: 1px solid #86b7fe;
  border-radius: 50%;
  color: #86b7fe;
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-arrow:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-arrow:hover:not(:disabled) {
  background-color: #86b7fe;
  color: white;
}

.btn-back {
  margin-right: 10px;
}

.btn-forward {
  margin-left: 10px;
}

.pagination-label {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
}
</style>
